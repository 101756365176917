html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    user-select: none;
    touch-action: none;
    background: #FFF4EB;
}

html {
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif';
}
